/* Reset */
@import 'tailwindcss/base';
@import 'reset';

/* Components */
@import 'tailwindcss/components';
@import 'buttons';
@import 'form';

/* Utilities */
@import 'tailwindcss/utilities';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';